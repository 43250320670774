@import "~bootstrap/scss/functions";

$custom-file-label-content: "Обзор";

$custom-file-text: (
    en: $custom-file-label-content
) !default;

// BLACK
// $theme-colors: (
//     "secondary": #CCC,
//     "light": #333,
// );

@import "~bootstrap/scss/bootstrap";
@import "~glyphicons-only-bootstrap/css/bootstrap.css";
@import "~select2";
@import "~select2-bootstrap4-theme";

// .bg-white {
//     background-color: #444!important;
// }

// .bg-dark {
//     background-color: #111!important;
// }

.custom-file-label {
    &::after {
      content: $custom-file-label-content;
    }
}

body {
    &::-webkit-scrollbar {
        width: 0.4em;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
        background-color: #444;
    }
}
